import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import Contact from '../components/Contact'
import Locations from '../components/Locations'

import pic01 from '../assets/images/what-we-are-made-of-peaches.png'
import pic02 from '../assets/images/what-we-are-made-of-grapes.png'
import pic03 from '../assets/images/what-we-are-made-of-pomegranates.png'
import pic04 from '../assets/images/what-we-are-made-of-citrus.png'
import pic05 from '../assets/images/what-we-are-made-of-kiwis.png'
import pic06 from '../assets/images/what-we-are-made-of-farming.png'
import pic07 from '../assets/images/what-we-are-made-of-harvesting.png'
import pic08 from '../assets/images/what-we-are-made-of-processing.png'
import pic09 from '../assets/images/what-we-are-made-of-technology.png'
import pic10 from '../assets/images/what-we-are-made-of-denim.png'
import picHarvest01 from '../assets/images/harvest-01.png'
import picHarvest02 from '../assets/images/harvest-02.png'
import picHarvest03 from '../assets/images/harvest-03.png'
import picHarvest04 from '../assets/images/harvest-04.png'
import picHarvest05 from '../assets/images/harvest-05.png'
import picHarvest06 from '../assets/images/harvest-06.jpg'
import picProcessing01 from '../assets/images/processing-01.jpg'
import picProcessing02 from '../assets/images/processing-02.jpg'
import picProcessing03 from '../assets/images/processing-03.jpg'
import picTech01 from '../assets/images/tech-01.jpg'
import picTech02 from '../assets/images/tech-02.jpg'
import picTech03 from '../assets/images/tech-03.jpg'

import { isLoggedIn } from "../services/auth"
import Login from './login'

const HomeIndex = () => {
    if (!isLoggedIn()) {
        return <Login />
    }
    return (
        <Layout>
            <Helmet
                title="Moonlight"
                meta={[
                    { name: 'description', content: 'Moonlight Companies is a California farming company based in Reedley, CA. We are situated in the heart of California’s San Joaquin Valley, the agricultural epicenter of the world.' },
                    { name: 'keywords', content: 'Moonlight, California, Farming, Peaches, Nectarines, Plums, Table Grapes, Citrus, Tree Fruit, Mighties, Cuties, San Joaquin Valley' },
                ]}
            >
            </Helmet>

            <Banner />

            <div id="main">
                <section id="one" className="tiles">
                    <article id="tree-fruit" style={{ backgroundImage: `url(${pic01})`, backgroundSize: `contain`, backgroundColor: `white` }}>
                        <header className="major">
                            <h3>Enjoy The "Tree" Fruits</h3>
                            <p>Of Our Labor</p>
                        </header>
                        <Link to="/tree-fruit" className="link primary"></Link>
                    </article>
                    <article id="table-grapes" style={{ backgroundImage: `url(${pic02})`, backgroundSize: `contain`, backgroundColor: `white` }}>
                        <header className="major">
                            <h3>Vine-Ripened Table Grapes</h3>
                            <p>You'll like them a bunch</p>
                        </header>
                        <Link to="/table-grapes" className="link primary"></Link>
                    </article>
                    <article id="pomegranates" style={{ backgroundImage: `url(${pic03})`, backgroundSize: `contain`, backgroundColor: `white` }}>
                        <header className="major">
                            <h3>Healthy, Delicious Pomegranates</h3>
                            <p>Big, Red & Ripe</p>
                        </header>
                        <Link to="/pomegranates" className="link primary"></Link>
                    </article>
                    <article id="citrus" style={{ backgroundImage: `url(${pic04})`, backgroundSize: `contain`, backgroundColor: `white` }}>
                        <header className="major">
                            <h3>California Citrus</h3>
                            <p>World Famous for a Reason</p>
                        </header>
                        <Link to="/citrus" className="link primary"></Link>
                    </article>
                    <article id="mighties" style={{ backgroundImage: `url(${pic05})`, backgroundSize: `contain`, backgroundColor: `white` }}>
                        <header className="major">
                            <h3>Nutrient-Dense, Heart-Healthy Kiwifruit</h3>
                            <p>It’s Mightie Delicious</p>
                        </header>
                        <Link to="/mighties" className="link primary"></Link>
                    </article>
                    <article style={{ backgroundImage: `url(${pic06})`, backgroundSize: `contain`, backgroundColor: `white` }}>
                        <header className="major">
                            <h3>We Are A California Farming</h3>
                            <p>Company</p>
                        </header>
                    </article>
                </section>
                <section id="bg-1">
                </section>
                <section id="two" className="spotlights">
                    <section id="company">
                        <div className="image">
                            <img src={pic07} alt="" />
                        </div>
                        <div className="content" id="specialized-harvesting">
                            <div className="inner">
                                <header className="major">
                                    <h3>Specialized Harvesting</h3>
                                </header>
                                <p>The Moonlight quality control process begins at the source—our orchards. All of our fruit is hand-picked and gently placed in heat-resistant plastic totes. While this meticulous method may take a little longer, it’s cleaner and safer than other harvesting techniques, plus it prevents bruising. All of our growers carefully monitor their crops to ensure Moonlight fruit is picked at the scientific peak of ripeness. It’s just part of our commitment to quality, and you’ll taste it in every bite.</p>
                                <div className="box alt">
                                    <div className="grid-wrapper">
                                        <div className="col-4"><span className="image"><img className="bordered-image" src={picHarvest01} alt="" /></span></div>
                                        <div className="col-4"><span className="image"><img className="bordered-image" src={picHarvest02} alt="" /></span></div>
                                        <div className="col-4"><span className="image"><img className="bordered-image" src={picHarvest03} alt="" /></span></div>
                                        <div className="col-4"><span className="image"><img className="bordered-image" src={picHarvest04} alt="" /></span></div>
                                        <div className="col-4"><span className="image"><img className="bordered-image" src={picHarvest05} alt="" /></span></div>
                                        <div className="col-4"><span className="image"><img className="bordered-image" src={picHarvest06} alt="" /></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="image">
                            <img src={pic08} alt="" />
                        </div>
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>Innovation In Processing</h3>
                                </header>
                                <p>This isn’t your grandfather’s packing shed. Moonlight Companies is home to a multi-million dollar, state-of-the-art packing facility. This fully-automated operation helps us quickly and efficiently package all of our fruits in order to get them to your table at the peak of ripeness. We go to extremes to ensure the facility remains spotless at all times, and we use the most stringent safety protocols to ensure our employees are always working in a safe and comfortable environment.</p>
                                <div className="box alt">
                                    <div className="grid-wrapper">
                                        <div className="col-4"><span className="image"><img className="bordered-image" src={picProcessing01} alt="" /></span></div>
                                        <div className="col-4"><span className="image"><img className="bordered-image" src={picProcessing02} alt="" /></span></div>
                                        <div className="col-4"><span className="image"><img className="bordered-image" src={picProcessing03} alt="" /></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="image">
                            <img src={pic09} alt="" />
                        </div>
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>The Latest Technology Streamlining Freshness</h3>
                                </header>
                                <p>Getting the freshest fruits from our orchards to your table isn’t easy. We just make it look that way. How do we do it? By consistently investing in the latest technology to help us efficiently streamline our operations. From computerized fruit sorting and automated packing systems to high-volume bagging machines and real-time inventory monitoring, we’ve got all the latest tools to help us give you the most flavorful, juiciest fruits under the moon!</p>
                                <div className="box alt">
                                    <div className="grid-wrapper">
                                        <div className="col-4"><span className="image"><img className="bordered-image" src={picTech01} alt="" /></span></div>
                                        <div className="col-4"><span className="image"><img className="bordered-image" src={picTech02} alt="" /></span></div>
                                        <div className="col-4"><span className="image"><img className="bordered-image" src={picTech03} alt="" /></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="image">
                            <img src={pic10} alt="" />
                        </div>
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>What We Are Made Of</h3>
                                </header>
                                <p>Since 1918, the Moonlight family of companies have delivered the finest fruits from California’s heartland to customers around the globe.</p>
                                <p>Quality, consistency, and freshness are the hallmarks of Moonlight. Pioneers in fruit packing and delivery, we’ve struck a fine balance between environmentally responsible farming practices, dedication to a safe and comfortable working environment, and ongoing investments in the latest technologies. These elements, combined with good old fashioned hard work, allow us to give you the freshest, juiciest fruits delivered to your table at the peak of ripeness.</p>
                                <p>Fruit is our focus: peaches, nectarines, plums, pluots, table grapes, oranges, lemons, mandarins and pomegranates to be exact. These are the staples of Moonlight. With nearly a century of experience growing, packing, and delivering them, we know these fruits better than anyone else.</p>
                                <p>Fresh, flavorful, sweet and juicy. That’s a Moonlight fruit. Try it for yourself and see what we’re made of.</p>
                                <ul className="actions">
                                    <li><a href="https://moonlightcompanies.applytojob.com/apply/" target="_blank" className="button">Careers / Empleo</a></li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </section>
                <section id="three">
                    <div className="inner">
                        <header className="major">
                            <h2>California Farming</h2>
                        </header>
                        <p>
                            Moonlight Companies is a California farming company based in Reedley, CA. We are situated in the heart of California’s San Joaquin Valley, the agricultural epicenter of the world.
                            </p>
                        <p>
                            We are committed to growing, packing, and delivering only the freshest, sweetest, juiciest fruits possible. That’s why we have partnered with a select group of growers who share our values.
                            </p>
                        <p>
                            Moonlight fruit comes from vineyards, orchards and groves located primarily along the Kings River and stretching throughout three Central California counties. Just like us, our partners are stewards of the land, using sustainable farming techniques while growing the most flavorful varieties you’ll find anywhere.
                            </p>
                    </div>
                </section>
            </div>

            <Contact id="contact" />
            <Locations />

        </Layout>
    )
}

export default HomeIndex