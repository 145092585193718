import React from 'react'

const Contact = (props) => (
    <section id="contact" className="contact">
        <h2 style={{ textAlign: `center`, paddingTop: `20px` }}>Contact Us</h2>
        <div className="inner">
            <section>
                <section>
                    <h3>Have You Tried Our Products?</h3>
                    <h4>We Want To Hear From You!</h4>
                    <hr/>
                </section>
                <form method="post" action="#">
                    <div className="field half first">
                        <label htmlFor="name">What store did you purchase your Moonlight fruit from?</label>
                        <input type="text" name="name" id="name" />
                    </div>
                    <div className="field half">
                        <label htmlFor="email">In what city and state is the store located?</label>
                        <input type="text" name="email" id="email" />
                    </div>
                    <div className="field">
                        <label htmlFor="message">What are your comments about the Moonlight fruit you purchased</label>
                        <textarea name="message" id="message" rows="6"></textarea>
                    </div>
                    <div className="field">
                        <label htmlFor="email">Your Email</label>
                        <input type="text" name="email" id="email" />
                    </div>
                    <ul className="actions">
                        <li><input type="submit" value="Send Message" className="special" /></li>
                        <li><input type="reset" value="Clear" /></li>
                    </ul>
                </form>
            </section>
            <section className="split">
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-envelope"></span>
                        <h3>Email</h3>
                        <ul style={{ listStyle: `none` }}>
                            <li>
                                <a href="#">sales@moonlightcompanies.com</a>
                            </li>
                            <li>
                                <a href="#">hr@moonlightcompanies.com</a>
                            </li>
                        </ul>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-phone"></span>
                        <h3>Phone</h3>
                        <ul style={{ listStyle: `none` }}>
                            <li>
                                <span>Corporate Office Tel: (559) 638-7799</span>
                            </li>
                            <li>
                                <span>Corporate Office Fax: (559) 638-7199</span>
                            </li>
                            <li>
                                <span>Shipping Tel: (559) 638-7799 (opt.1)</span>
                            </li>
                            <li>
                                <span>California Sales Tel: (559) 637-7799</span>
                            </li>
                            <li>
                                <span>Maryland Sales Tel: (410) 796-9077</span>
                            </li>
                            <li>
                                <span>Sales Fax: (559) 637-7199</span>
                            </li>
                            <li>
                                <span>Office: (559) 643-8977</span>
                            </li>
                        </ul>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-globe"></span>
                        <h3>Address</h3>
                        <ul style={{ listStyle: `none` }}>
                            <li>
                                <h6>Moonlight Companies</h6>
                                <span>17719 East Huntsman<br />
                                Reedley, CA 93654<br />
                                United States of America</span>
                            </li>
                        </ul>
                    </div>
                </section>
            </section>
        </div>
    </section>
)

export default Contact
