import React from 'react'

const Locations = (props) => (
    <section id="contact">
        <div className="inner">
            <section style={{display: `flex`, flexWrap: `wrap`}}>
                <div className="contact-method">
                    <h3><a target="_blank" href="https://www.google.com/maps/place/17719+E+Huntsman+Ave,+Reedley,+CA+93654/@36.5823765,-119.4739742,17z/data=!3m1!4b1!4m5!3m4!1s0x8094e3196ae04465:0x62ad4c6578778d3c!8m2!3d36.5823765!4d-119.4717855">Plant 01</a></h3>
                    <span>17719 E. Huntsman Ave<br />
                                Reedley, CA 93654<br />
                                United States of America</span>
                </div>
                <div className="contact-method">
                    <h3><a target="_blank" href="https://www.google.com/maps/place/1300+I+St,+Reedley,+CA+93654/@36.5927429,-119.4526304,715m/data=!3m2!1e3!4b1!4m5!3m4!1s0x8094e2c28e5b81e1:0x9244cb00a2aa1ee2!8m2!3d36.5927429!4d-119.4504417?hl=en&authuser=0">Plant 02</a></h3>
                    <span>1300 I Street<br />
                                Reedley, CA 93654<br />
                                United States of America</span>
                </div>
                <div className="contact-method">
                    <h3><a target="_blank" href="https://www.google.com/maps/place/1440+I+St,+Reedley,+CA+93654/@36.5915535,-119.4513507,715m/data=!3m2!1e3!4b1!4m5!3m4!1s0x8094e2e9cea68ce7:0x42e1e2fcbcd0a409!8m2!3d36.5915535!4d-119.449162?hl=en&authuser=0">Plant 03</a></h3>
                    <span>1440 I Street<br />
                                Reedley, CA 93654<br />
                                United States of America</span>
                </div>
                <div className="contact-method">
                    <h3><a target="_blank" href="https://www.google.com/maps?q=1770+E.+Huntsman+Ave.+Reedley,+CA+93654&ie=UTF-8&ei=XpO4UYGINaWUiQLMtoDQBQ&ved=0CAoQ_AUoAg">Plant 04</a></h3>
                    <span>17770 E. Huntsman Ave.<br />
                                Reedley, CA 93654<br />
                                United States of America</span>
                </div>
                <div className="contact-method">
                    <h3><a target="_blank" href="https://www.google.com/maps?q=1500+W.+Manning+Ave.+Reedley,+CA+93654&rlz=1C1CHFX_enUS394US394&um=1&ie=UTF-8&hl=en&sa=N&tab=wl">Plant 05</a></h3>
                    <span>1500 W. Manning Ave.<br />
                                Reedley, CA 93654<br />
                                United States of America</span>
                </div>
            </section>
        </div>
    </section>
)

export default Locations
